import { render, staticRenderFns } from "./index.vue?vue&type=template&id=d7d5f316&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=d7d5f316&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7d5f316",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonSectionTitle: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/common/section-title.vue').default,TopPickUpItem: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/top/pick-up/item.vue').default,CommonWrapSection: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/common/wrap-section.vue').default})
