import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        data: {
            type: Array,
            default: function () { return []; }
        }
    },
    data: function () {
        var swiper = {};
        return {
            swiper: swiper,
            currentIndex: 1
        };
    },
    mounted: function () {
        this.swiper = this.initCarousel(this.$swiper);
    },
    methods: {
        initCarousel: function (Swiper) {
            var _this = this;
            var options = {
                modules: [this.$swiperModules.Pagination, this.$swiperModules.Navigation],
                slidesPerView: 1,
                allowTouchMove: false,
                loop: this.data.length > 1,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                        loop: this.data.length > 2
                    },
                    1250: {
                        slidesPerView: 3,
                        pagination: false,
                        spaceBetween: 60,
                        centeredSlides: this.data.length > 3,
                        centeredSlidesBounds: this.data.length > 3,
                        initialSlide: 1,
                        loop: this.data.length > 3
                    }
                },
                on: {
                    slideChange: function (e) {
                        _this.currentIndex = e.realIndex;
                    }
                }
            };
            return new Swiper('.swiper', options);
        },
        gotoSlide: function (index) {
            this.swiper.slideToLoop(index + 1);
        }
    }
});
