import { defineComponent } from 'vue';
import { useMenu } from '~/composables/use-menu';
export default defineComponent({
    setup: function () {
        var partnerData = useMenu().partnerData;
        var typeBlock = function (type) {
            switch (type) {
                case 'ABlock':
                    return 'A';
                case 'BBlock':
                    return 'B';
                case 'CBlock':
                    return 'C';
                case 'DBlock':
                    return 'D';
                default:
                    return 'E';
            }
        };
        return { partnerData: partnerData, typeBlock: typeBlock };
    }
});
