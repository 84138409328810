import { defineComponent } from 'vue';
var hashs = [{ name: 'INFORMATION' }, { name: '開催情報' }];
export default defineComponent({
    name: 'CTopInformation',
    props: {
        data: {
            type: Object,
            default: function () { return ({}); }
        }
    },
    setup: function () {
        // APIService.getNews().then((responsive) => {
        //   if (responsive) {
        //     const data = responsive.data as NewsResponse
        //     inforNews.value = data.news || null
        //   }
        // })
        return { hashs: hashs };
    },
    computed: {
        dataContent: function () {
            if (!this.data.content) {
                return '';
            }
            var parser = new DOMParser();
            var htmlDoc = parser.parseFromString(this.data.content, 'text/html');
            var pTags = htmlDoc.getElementsByTagName('p');
            for (var i = 0; i < pTags.length; i++) {
                var pTag = pTags[i];
                var bTags = pTag.getElementsByTagName('b');
                for (var j = 0; j < bTags.length; j++) {
                    var bTag = bTags[j];
                    if (!bTag.innerText) {
                        pTag.innerHTML = '<br/>';
                    }
                }
            }
            return htmlDoc.body.innerHTML;
        }
    }
});
