import { defineComponent } from 'vue';
import config from '@/configs';
export default defineComponent({
    setup: function () {
        var instagramUrl = config.sns.instagram;
        var twitterUrl = config.sns.twitter;
        return {
            instagramUrl: instagramUrl,
            twitterUrl: twitterUrl
        };
    }
});
