import { defineComponent, onMounted, ref } from 'vue';
import APIService from '@/services';
export default defineComponent({
    setup: function () {
        var imageList = ref([]);
        var getInstagramImages = function () {
            APIService.getInstagramMedia().then(function (responsive) {
                var _a;
                var data = (responsive || {}).data;
                if (((_a = data === null || data === void 0 ? void 0 : data.items) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    var list = data.items.length > 6 ? data.items.slice(0, 6) : data.items;
                    imageList.value = list;
                }
            });
        };
        // const config = useRuntimeConfig()
        onMounted(function () {
            getInstagramImages();
        });
        return { imageList: imageList };
    }
});
