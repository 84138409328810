import { defineComponent } from 'vue';
import { useDevice } from '~/composables/use-device';
export default defineComponent({
    props: {
        type: {
            type: String,
            required: false,
            default: 'A'
        },
        listPartner: {
            type: Array,
            default: function () { return []; }
        },
        title: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        backgroundColor: {
            type: String,
            default: '#F2F2F2'
        }
    },
    setup: function (props) {
        var isMobile = useDevice().isMobile;
        var classItem = function (index) {
            var len = 0;
            switch (props.type) {
                case 'B':
                    len = isMobile.value ? 1 : 2;
                    if (index >= props.listPartner.length - len) {
                        return ['mb-0'];
                    }
                    return ['mb-[40px]'];
                case 'C':
                    len = isMobile.value ? 1 : 3;
                    if (index >= props.listPartner.length - len) {
                        return ['mb-0'];
                    }
                    return ['mb-[40px]'];
                case 'D':
                    len = isMobile.value ? 1 : 4;
                    if (index >= props.listPartner.length - len) {
                        return ['mb-0'];
                    }
                    return ['mb-[40px]'];
                default:
                    return [];
            }
        };
        return { classItem: classItem };
    }
});
